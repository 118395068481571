import Vue from 'vue'

export const eventBus = new Vue({
    // 메소드로 만들어 형제 컴포넌트에서 선언한 userWasEdited를 메소드화
    methods:{
        updateTopBarData() {
            this.$emit('updateTopBarData');
        },
        getBalance(balance) {
            this.$emit('getBalance',balance);
        },
        getData() {
            this.$emit('getData');
        },
        getNowTime(nowTime) {
            this.$emit('getNowTime',nowTime);
        },
        getSiteInfo() {
            this.$emit('getSiteInfo');
        },
        showAlert(text,option,action) {
            this.$emit('showAlert',text,option,action);
        },
        initAlert() {
            this.$emit('initAlert');
        },
        disconnectSocket() {
            this.$emit('disconnectSocket')
        },
        showAlarm(body) {
            this.$emit('showAlarm',body)
        },
        setGameDataUsingSocket(body) {
            this.$emit('setGameDataUsingSocket',body)
        }

    }
})
