<template>
  <div>
    <v-navigation-drawer v-model="drawer" app v-if="$vuetify.breakpoint.smAndDown" class="overflow-y-auto">
      <v-list
          nav
      >
        <v-list-item-group
            active-class="selectedMenu"
        >
          <v-list-item
              v-for="item in menuItems"
              :key="item.title"
              :to="item.path"
              link
              style="box-shadow: 1px 0px 2px rgba(33,33,33,0.6);"
          >
            <v-list-item-title
                style="font-size:13px;"
            >{{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <!--        <v-list-tile-->
        <!--            v-for="item in menuItems"-->
        <!--            :key="item.title"-->
        <!--            :to="item.path">-->

        <!--          <v-list-tile-content>{{ item.title }}</v-list-tile-content>-->
        <!--        </v-list-tile>-->
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        app
        color=""
        dark
        dense
        id="appBar"
        elevation="0"
    >

      <v-container
          :fluid="$route.matched[0].props.appBarFullWidth"
          class="py-0"
          :class="$vuetify.breakpoint.mdAndUp ? '': 'px-0'"
      >
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <span class="hidden-md-and-up">
              <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            </span>

            <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="@/assets/logo.png"
                transition="scale-transition"
                :width="$vuetify.breakpoint.mdAndUp ? 80: 48"
                @click="goLink('main')"
                style="cursor: pointer"
            />


          </div>
          <!--          <v-spacer></v-spacer>-->
          <user-pc
              v-if="$vuetify.breakpoint.mdAndUp"
              :nowTime="nowTime"
              :balance="balance"
              :role="role"
              :unreadMessage="unreadMessage"
              :nickname="nickname"
              :gradeValue="gradeValue"
              v-on:logout="logout"
              v-on:goLink="goLink"

          ></user-pc>
          <user-mobile
              v-else
              :nowTime="nowTime"
              :balance="balance"
              :role="role"
              :unreadMessage="unreadMessage"
              :nickname="nickname"
              :gradeValue="gradeValue"
              v-on:logout="logout"
              v-on:goLink="goLink"

          >
          </user-mobile>

        </div>
      </v-container>
      <template v-slot:extension v-if="$vuetify.breakpoint.mdAndUp">
        <div class="">
          <v-container
              :fluid="$route.matched[0].props.appBarFullWidth"
          >
            <v-tabs class="menuTab"
                    v-model="menuTab"
                    centered
                    hide-slider
                    show-arrows
            >
              <!--              <v-tabs-slider color="yellow"></v-tabs-slider>-->
              <v-tab
                  v-for="(item,i) in menuItems"
                  :key="i"
                  :active-class="$route.path !== '/message' && $route.path !== '/' ? 'white--text selectedMenu': ''"
                  @click="checkMenuTab(item.path)"
                  style="font-size:12px;font-weight:bold;"
              >
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </v-container>
        </div>

      </template>
    </v-app-bar>
    <under-maintenance-dialog
        :dialog.sync=dialog.underMaintenance
        :text=dialog.underMaintenanceText
    ></under-maintenance-dialog>
    <confirm-dialog
        :dialog.sync="dialog.confirm"
        :confirmData="dialog.confirmData"
        v-on:confirmLeftBtnAction="confirmLeftBtnAction"
        v-on:confirmRightBtnAction="confirmRightBtnAction">
    </confirm-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import userPc from "@/components/_Default/User/Pc"
import userMobile from "@/components/_Default/User/Mobile"
import {eventBus} from "@/plugins/eventBus";
import Stomp from "webstomp-client";
import SockJS from "sockjs-client";


export default {
  name: "TopBar",

  data: () => ({
    memberCheckInterval: null,
    memberStatusCheckInterval: null,
    socketInterval: null,
    interval: null,
    drawer: false,
    nowTime: 0,
    balance: {},
    unreadMessage: 0,
    nickname: '',
    gradeValue: '',
    menuItems: [
      /*{title: '라이브', path: '/live'},*/
      {title: '크로스', path: '/game/sports/CROSS'},
      {title: '스페셜', path: '/game/sports/SPECIAL'},
      {title: '라이브', path: '/game/LIVE'},
      /*{title: '인플레이', path: '/game/INPLAY'},*/
      {title: '카지노', path: '/casino/live'},
      {title: '슬롯', path: '/casino/slot'},
      {title: '가상게임', path: '/game/virtual'},
      {title: '미니게임', path: '/game/mini'},
      {title: '경기결과', path: '/result'},
      {title: '베팅내역', path: '/mybet/SPORTS_GAME'},
      {title: '공지사항', path: '/notice/NOTICE'},
      /*{title: '게시판', path: '/board'},*/
      {title: '이벤트', path: '/notice/EVENT'},
      {title: '충전', path: '/deposit'},
      {title: '환전', path: '/withdrawal'},
      {title: '포인트', path: '/point'},
      {title: '고객센터', path: '/customer'},
      {title: '출석체크', path: '/attendance'},

    ],
    dialog: {
      alert: false,
      alertOption: {
        persistent: true
      },
      alertText: '',
      alertActionType: '',
      underMaintenance: false,
      underMaintenanceText: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
    },
    menuTab: null,
    currPath: '',
    socket: null,
    stompClient: null,
    connected: false,
    subscriptionId: '',
    callApiFlag: true,
    role: '',
  }),
  components: {
    userPc,
    userMobile
  },
  methods: {
    setMenuTab: function () {
      this.currPath = this.$route.path

      if (this.$route.path.includes('mybet')) {
        this.menuTab = this.menuItems.findIndex(tab => tab.path.includes('mybet'))
      } else {
        this.menuTab = this.menuItems.findIndex(tab => tab.path === this.$route.path)
      }


    },
    checkMenuTab: function (targetPath) {
      if (this.unreadMessage > 0 && this.$route.path.includes('/message')) {
        this.checkUnreadMessage()

      } else {
        if (targetPath === this.currPath) {
          eventBus.getData()
        } else {
          this.$router.push({path: targetPath})
        }
      }

    },
    goLink: function (target) {
      if (this.unreadMessage > 0 && this.$route.path.includes('/message')) {
        this.checkUnreadMessage()

      } else {
        this.$goLink(target)
      }


    },

    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },

    // 쿠키 전체 삭제하기
    allDelCookies: function () {
      const cookies = document.cookie.split('; '); // 배열로 반환
      const expiration = 'Sat, 01 Jan 1972 00:00:00 GMT';

      // 반목문 순회하면서 쿠키 전체 삭제
      if (!document.cookie) {
      } else {
        for (let i = 0; i < cookies.length; i++) {
          // const uname = cookies[i].split('=')[0];
          // document.cookie = `${uname}=; expires=${expiration}`;
          document.cookie = cookies[i].split('=')[0] + '=; expires=' + expiration;
          // document.cookie = cookies[i].split('=')[0] + '=; expires=' + expiration + '; domain =' + domain;
        }
      }
    },


    logout: function () {
      this.dialog.confirmData.leftBtn = '확인'
      this.dialog.confirmData.rightBtn = '취소'
      this.dialog.confirmData.title = '로그아웃'
      this.dialog.confirmData.content = '로그아웃 하시겠습니까?'
      this.dialog.confirm = true
    },
    confirmRightBtnAction: function () {
      this.dialog.confirmData.leftBtn = ''
      this.dialog.confirmData.rightBtn = ''
      this.dialog.confirmData.title = ''
      this.dialog.confirmData.content = ''
      this.dialog.confirm = false
    },
    confirmLeftBtnAction: async function () {
      //로그아웃시 팝업 쿠기 삭제 (밀리언 요청)
      this.allDelCookies()
      //로그아웃 api 추가
      await axios
          .post("/app/auth/signout", {})
          .then(response => {
            console.log('refresh !!! STORE', response)

            // 성공적으로 로그인이 되었을 경우
            if (response.status === 200) {

              console.log('signout success')

            } else {
              console.log('signout fails')
            }

          }).catch(async ex => {
            console.log("signout fail", ex)
          });
      this.$logout()
    },

    setDate() {
      let weekday = ['일', '월', '화', '수', '목', '금', '토']
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1;
      let date = new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate();
      let week = weekday[new Date().getDay()];
      let hh = new Date().getHours() < 10 ? "0" + new Date().getHours() : new Date().getHours();
      let mm = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
      return {
        'year': year
        , 'month': month
        , 'date': date
        , 'week': week
        , 'hh': hh
        , 'mm': mm
        , 'ss': ss
      }
    },
    nowTimes() {
      this.nowTime =
          // this.setDate().year + "-" + this.setDate().month + "-" + this.setDate().date + "-" +
          this.setDate().year + "-" + this.setDate().month + "-" + this.setDate().date + " (" + this.setDate().week + ") " + "<br />" + this.setDate().hh + ":" + this.setDate().mm + ":" + this.setDate().ss;
      // this.setDate().hh + ":" + this.setDate().mm + ":" + this.setDate().ss;
      eventBus.getNowTime(this.nowTime.replace('<br />', ''))
    },
    getSiteInfo: async function () {
      let result = await this.$updateSiteInfo()
      if (result.underMaintenance) {
        this.dialog.underMaintenance = true
        console.log(result.constructionText)
        if (result.data.constructionText.length > 0) {
          this.dialog.underMaintenanceText = result.data.constructionText
        } else {
          this.dialog.underMaintenanceText = '사이트 점검 중이므로 이용하실 수 없습니다.';
        }
      }
      console.log('localStorage', localStorage)
      console.log('localStorage', JSON.parse(localStorage.useCasino))
    },
    getMember: async function () {
      if (this.callApiFlag) {
        let result = {}
        if (this.$route.path !== '/login' && !this.$route.path.includes('/join')) {
          result = await this.$updateMyInfo()
          this.callApiFlag = result.flag
          if (!this.callApiFlag) {
            if (result.status === 403) {
              this.dialog.alert = true;
              if (result.msg.length > 0) {
                this.dialog.alertText = result.msg
              } else {
                this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.';
              }
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else if (result.status === 401 || result.status === 200) {
              this.callApiFlag = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = '잠시 후 다시 시도해주세요.'
              this.dialog.alertActionType = ''
              this.dialog.alertOption.persistent = true
            }


          }
        }

      }

      this.nickname = localStorage.nickname
      this.role = localStorage.role
      // console.log(localStorage)
      if (localStorage.showMemberLevel === 'false') {
        this.gradeValue = ''
      } else {
        this.gradeValue = localStorage.gradeValue
      }

    },
    getBalance: function () {
      axios
          .get('/app/member/balance',
              {
                params: {},
              })
          .then(response => {
            console.log('balance', response)
            this.balance = response.data
            if (localStorage.role === 'ROLE_MEMBER' && (this.balance.hasCommissionConfig || this.balance.hasInvitationConfig)) {
              if (this.menuItems.findIndex(v => v.title === '롤링') === -1) {
                let tempMenuItem = [...this.menuItems]
                let pointIndex = tempMenuItem.findIndex(v => v.title === '포인트') + 1
                tempMenuItem.splice(pointIndex, 0, {title: '롤링', path: '/rolling'})
                this.menuItems = tempMenuItem
              }


            }

            eventBus.getBalance(this.balance)
          })
          .catch(async ex => {
            console.log(ex)
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getBalance()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                }
                this.dialog.alert = true;
                if (refreshTokenResult.msg !== '') {
                  this.dialog.alertText = refreshTokenResult.msg
                } else {
                  this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
                }

              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              if (ex.response.data !== '') {
                this.dialog.alertText = ex.response.data.message
              } else {
                this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
              }
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }
          })

    },
    getUnreadMessage: async function () {
      await axios
          .get('/app/messages/unread',
              {
                params: {},
              })
          .then(response => {
            // console.log(response.data)
            this.unreadMessage = response.data
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getUnreadMessage()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                }
                this.dialog.alert = true;
                if (refreshTokenResult.msg !== '') {
                  this.dialog.alertText = refreshTokenResult.msg
                } else {
                  this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
                }

              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              if (ex.response.data !== '') {
                this.dialog.alertText = ex.response.data.message
              } else {
                this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
              }
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })
      if (this.unreadMessage > 0 && !this.$route.path.includes('/message')) {
        this.checkUnreadMessage();
      }


    },

    checkUnreadMessage: function () {
      this.dialog.alertText = '읽지 않은 매세지가 있습니다. 확인하시겠습니까?';
      this.dialog.alert = true;
      this.dialog.alertActionType = 'goMessage'
    },


    getData: function () {
      // this.getSiteInfo();
      // this.getMember()
      this.getBalance()
      this.getUnreadMessage()
      this.setMenuTab()
    },
    memberStatusCheck: function () {
      this.$memberStatusCheck()
    },

    showAlarm: function (item) {
      this.dialog.alert = true;
      this.dialog.alertText = item.message;
      this.dialog.alertActionType = '';
      eventBus.updateTopBarData()
    },

    initUserData: function () {
      if (this.nickname === '' && localStorage.nickname !== '') {
        this.nickname = localStorage.nickname
        this.gradeValue = localStorage.gradeValue
        this.role = localStorage.role
      }
    }

  },


  mounted() {
    this.initUserData()
  },


  computed: {
    // setMenuColor() {
    //   let menuClass = this.$route.path !== '/message' && this.$route.path !== '/' ? 'white--text selectedMenu': ''
    //   return {
    //     // height: `${this.cartHeight}px`,
    //     // height: `${this.betListHeight} px`,
    //     menuClass
    //   }
    // }
  },
  created() {
    eventBus.$off('updateTopBarData')
    eventBus.$on('updateTopBarData', () => {
      this.getData()
    })
    eventBus.$off('showAlarm')
    eventBus.$on('showAlarm', (body) => {
      this.showAlarm(body)
    })

    this.nowTimes();
    this.setDate()

    setInterval(this.nowTimes.bind(this), 1000);
    //회원 상태 체크
    this.memberStatusCheckInterval = setInterval(this.memberStatusCheck.bind(this), 3000);
    //회원정보 불러오기
    this.memberCheckInterval = setInterval(this.getMember.bind(this), 5000);

  },
  beforeDestroy() {
    clearInterval(this.memberCheckInterval)
    clearInterval(this.memberStatusCheckInterval)
  },


  watch: {
    $route(to, from) {
      // if (this.connected !== true) {
      //   this.connect();
      // }
      console.log('route!!!!')
      this.currPath = to.path
      this.initUserData()
    },
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog.alert) {
          this.showAlert()
        }
      },
      deep: true

    }
  }
}
</script>

<style scoped>
/*ul {*/
/*  list-style: none;*/
/*}*/
/*ul li {*/
/*  float:left;*/
/*  font-size: 24px;*/
/*}*/
/*.menuTag {*/
/*  text-decoration: none;*/
/*  color: white;*/
/*}*/
/deep/ #appBar .v-toolbar__extension {
  justify-content: center;
  background-color: #3a3838;
  /*border-bottom: 1 px solid #FFAE00 !important;*/
}

/deep/ #appBar .v-toolbar__content {
  padding: 4px 8px;
}

.v-tab {
  /*font-size: 0.905rem;*/
  /*font-family: NanumGothic !important;*/
  font-weight: 600;
}

.menuTab {
  /*border-bottom: 1 px solid #FFAE00 !important;*/
  /*max-height: 48 px;*/
}

/deep/ .menuTab .v-slide-group {
  background-color: #413f3f;
}

.selectedMenu, .v-tab--active {
  /*background-color: #2196f3*/
  background-color: #f18b38 !important;
  color: #fff !important;
  /*background: radial-gradient(#762006bf, transparent);*/
  font-weight: 0 !important;

}

.selectedMenu .v-list-item__title {
  color: #FFFFFF;
}

p {
  margin-right: 5px
}

nav {
  height: calc(var(--vh, 1vh) * 100) !important;
}


</style>
