<template>
  <v-dialog
      :value="dialog"
      @input="alertAction"
      light
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :persistent="option.persistent ? option.persistent : false"
  >
    <v-card>
      <v-card-title  class="justify-center">
        <div class="text-center" style="color:#FFF">
          점검중
        </div>

      </v-card-title>
      <v-card-text class="pa-5 text-center">
<!--        <slot>{{ text }}</slot>-->
<!--        <div v-html="text" style="font-size: 20px"></div>-->
        <pre style="color:#FFF;font-size:15px">{{text}}</pre>

      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TextAlert",
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    text: {
      type: String,
    },
    option: {
      type:Object,
      default:Object
    }

  },
  methods: {
    alertAction: function () {
      this.$emit('alertAction');
    },
  }
}
</script>

<style scoped>

</style>